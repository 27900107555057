<template>
  <div>
    <b-card>
      <advanced-table
        :tableColumns="tableColumns"
        dataAction="ftp/GET_FTP"
        :createRoute="{ name: 'ftp-details' }"
        ref="ftpTable"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'ftp-details', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('edit') }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteFTP(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </advanced-table>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue';
import AdvancedTable from '@/views/components/AdvancedTable.vue';
import i18n from '@/libs/i18n';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    AdvancedTable,
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
    BFormCheckbox,
  },
  data() {
    return {
      tableColumns: [
        { key: 'mac', label: i18n.tc('mac'), sortable: true },
        //{ key: 'ip', label: i18n.tc('ip'), sortable: true },
        { key: 'username', label: i18n.tc('username'), sortable: true },
        { key: 'password', label: i18n.tc('password'), sortable: true },
        { key: 'actions', label: i18n.tc('actions') },
      ],
    };
  },
  methods: {
    openModal(userId, email) {
      this.devicePermissionUser = { userId, email };
      this.$router.push({ name: '' });
      this.$bvModal.show('modal-edit');
    },
    deleteFTP(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('confirm_ftp_deletation_description'), {
          title: this.$t('confirm_deletation'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('yes'),
          cancelTitle: this.$t('no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            const res = await this.$store.dispatch('ftp/DELETE_FTP', id);
            if (res) {
              this.$refs.ftpTable.refresh();
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('deleted'),
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: this.$t('ftp_deleted'),
                },
              });
            }
          }
        });
    },
  },
};
</script>

<style></style>
